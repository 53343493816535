.Navbar {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.Navbar div.home-image {
  background: url("./media/home.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  height: 8.5vh;
  margin: 0 auto;
  margin-top: 2%;
  width: 8.5vh;
}