    .Home {
      width: 90%;
      margin: 0 auto;
      align-items: center;
      font-weight: 300;
    }

    .Home .App-header {
      background-clip: text;
      background-image: linear-gradient(220deg, #00FFC6 50%, #43919B 50%, #43919B 50%);
      background-clip: text;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      color: transparent;
      font-size: 2.5rem;
      font-weight: 200;
      padding-top: 1rem;
      text-shadow: rgba(16, 205, 189, 0.3) 0px 3px 3px;
      text-transform: uppercase;
      letter-spacing: 2px;
      width: 100%;
      margin-bottom: 2rem;
      text-transform: capitalize;
    }

    @media (prefers-color-scheme: dark) {
      .Home .App-header {
        color: #1FA79D;
      }
    }

    @media only screen and (min-width: 400px) {
      body {
        width: 60%;
        margin: 0 auto;
        align-items: center;
      }

      .Home .App-header {
        width: 100%;
      }
    }

    @media only screen and (max-width: 767px) {
      .Home .App-header {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 10%;
        align-items: center;
        background-image: linear-gradient(220deg, #43919B, #43919B, #00FFC6);
      }
    }

    .Home .game-btn {
      border-top: 2px solid rgba(16, 205, 189, 0.3);
      border-left: 2px solid rgba(16, 205, 189, 0.3);
      border-right: 2px solid rgba(16, 205, 189, 0.3);
      color: rgba(105, 205, 218, 1);
      cursor: pointer;
      background-color: #ffffff;
      box-shadow: rgba(16, 205, 189, 0.3) 0px 5px 1px;
      text-transform: capitalize;
      text-decoration: none;
      width: 100%;
      display: inline-block;
      outline: 0;
      text-align: center;
      cursor: pointer;
      padding: 17px 30px;
      line-height: 30px;
      transition: all .5s ease-in;
      margin-bottom: 3rem;
      font-size: 1rem;
      font-weight: 300;
      border-radius: 41% 61% 74% 28% / 78% 48% 62% 29%;
    }

    .Home .game-btn:hover {
      background-color: rgba(34, 201, 187, 0.585);
      cursor: pointer;
      color: #ffffff;
    }

    .Home .game-btn-dark {
      border-radius: 41% 61% 74% 28% / 78% 48% 62% 29%;
      border-top: 2px solid rgba(16, 205, 189, 0.3);
      border-left: 2px solid rgba(16, 205, 189, 0.3);
      border-right: 2px solid rgba(16, 205, 189, 0.3);
      background-color: rgba(34, 201, 187, 0.585);
      cursor: pointer;
      color: #ffffff;
      box-shadow: rgba(16, 205, 189, 0.3) 0px 5px 1px;
      text-transform: capitalize;
      text-decoration: none;
      width: 100%;
      display: inline-block;
      outline: 0;
      text-align: center;
      cursor: pointer;
      padding: 17px 30px;
      line-height: 30px;
      transition: all .5s ease-in;
      margin-bottom: 3rem;
      font-size: 1.05rem;
      font-weight: 200;
    }

    .Home .game-btn-dark:hover {
      color: rgba(34, 201, 187, 0.585);
      cursor: pointer;
      background-color: #ffffff;
    }