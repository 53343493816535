.RoomNumbers {
  font-weight: 300;
  color: darkcyan;
  margin: 0 auto;
  width: 90%;
}

.RoomNumbers .cards {
  margin: 2rem auto;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  gap: 10px 0px;
  justify-content: center;
  place-items: center;
}

.RoomNumbers .game-description {
  grid-column-start: 1;
  grid-column-end: -1;
  padding: 0 0 2rem 0;
  text-align: justify;
  font-weight: 300;
  font-style: italic;
  margin: 0;
}

.RoomNumbers .hide-result {
  display: none;
}

.RoomNumbers .feedback {
  margin-top: 2%;
  position: fixed;
  z-index: 500;
  width: 15%;
  margin-left: .8%;
}

.feedback .correct {
  background-color: rgba(199, 253, 234, 0.93);
  border-radius: 41% 61% 74% 28% / 78% 48% 62% 29%;
  padding: 1rem;
  font-size: 1.2rem;
  display: block;
  margin: 0 auto;
}

.feedback .incorrect {
  background-color: rgb(241, 198, 193);
  border-radius: 41% 61% 74% 28% / 78% 48% 62% 29%;
  color: darkcyan;
  padding: 1rem;
  font-size: 1.2rem;
  display: block;
  margin: 0 auto;
}

.RoomNumbers .show-result {
  padding-top: 5%;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  background-color: #00808034;
  padding: 2.5rem;
}

.show-result h2 {
  margin-top: 0;
  font-size: 2.5em;
  font-weight: 200;
  font-style: normal;
  text-align: center;
  text-transform: capitalize;
}

.show-result h4 {
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  text-align: center;
}

.RoomNumbers .back-btn {
  background-color: #00808034;
  border: none;
  border-radius: 41% 61% 74% 28% / 78% 48% 62% 29%;
  color: darkcyan;
  padding: 1.5% 5%;
  margin-bottom: 5%;
}

@media (max-width: 767px) {
  .RoomNumbers .cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .RoomNumbers .game-description {
    width: 82.5%;
    margin: 0 auto;
  }

  .RoomNumbers .feedback {
    width: 45%;
  }
}

@media (min-width: 600px) {
  .RoomNumbers .cards {
    grid-template-columns: repeat(5, 1fr);
    gap: 5px, 0px;
  }

  .RoomNumbers .game-description {
    width: 91%;
    margin: 0 auto;
    font-size: 1.3rem;
  }
}