@import url('https://fonts.googleapis.com/css2?family=Ysabeau:ital,wght@0,1;0,100;0,200;0,300;0,400;0,500;0,700;1,1;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  font-family: "Ysabeau", sans-serif;
  font-weight: 300;
}

.App {
  text-align: center;
  animation: fade-in 2.5s;
}

.App-link {
  color: #61dafb;
}

.spinner {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.spin {
  background-image: url("./media/home.png");
  background-size: contain;
  width: 8.5vh;
  height: 8.5vh;
  display: block;
  margin: 0 auto;
  opacity: 0;
  background-repeat: no-repeat;
  animation: rotateAnimation 3s;
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }

  95% {
    opacity: 0.1;
    transform: rotate(360deg);
  }

  100% {
    opacity: 0;
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}