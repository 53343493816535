.Card {
  position: relative;
  padding: 1%;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: black;
}

.chosen .overlay {
  background: rgba(27, 45, 46, 0.471);
  color: rgb(238, 246, 248);
}

.hide {
  display: none;
}

.Card:hover {
  cursor: pointer;
}

.gem-image {
  width: 80px;
}

.overlay {
  background: rgba(96, 157, 159, 0.78);
  color: white;
  display: flex;
  font-style: italic;
  font-weight: 500;
  font-size: 1.3rem;
  text-shadow: 0.5px 0.5px turquoise;
  justify-content: center;
  align-items: center;
  position: absolute;
  text-transform: capitalize;
  border-radius: 54% 61% 41% 73% / 52% 48% 62% 59%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.7rem;
}

.overlay:hover {
  background: rgba(27, 45, 46, 0.471);
  color: rgb(238, 246, 248);
}